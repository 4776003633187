'use client'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

import styles from './RichText.module.css'

const RichTextBoldButton = () => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleBold().run()}
      disabled={!editor.can().chain().focus().toggleBold().run()}
      data-is-active={editor.isActive('bold')}
      aria-label="Bold"
      className={styles.bold}
    >
      B
    </button>
  )
}

export default RichTextBoldButton
