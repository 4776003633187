'use client'
import classNames from 'classnames'
import {
  CSSProperties,
  ChangeEventHandler,
  ForwardedRef,
  ReactNode,
  forwardRef,
  useRef,
} from 'react'
import { useRichTextContent } from './RichTextProvider'

import styles from './RichText.module.css'

export type RichTextProps = {
  name?: string
  required?: boolean
  fixedHeight?: string
  minHeight?: string
  children?: ReactNode
  className?: string
  variant?: 'outlined' | 'ghost'
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const RichText = (
  {
    fixedHeight,
    children,
    className,
    variant = 'outlined',
    onChange,
    minHeight,
    ...hiddenInputProps
  }: RichTextProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) => {
  const { editor } = useRichTextContent()

  const inputRef = useRef<HTMLInputElement | null>(null)

  if (!editor) return null

  return (
    <div
      ref={forwardedRef}
      className={classNames(styles.richText, className)}
      style={
        {
          '--fixed-height': fixedHeight,
          '--min-height': minHeight,
        } as CSSProperties
      }
      data-variant={variant}
    >
      {children}

      <input
        type="hidden"
        ref={inputRef}
        value={editor?.getHTML()}
        {...hiddenInputProps}
      />
    </div>
  )
}

export default forwardRef(RichText)
