'use client'
import Image, { ImageProps } from 'next/image'
import { ReactElement, SyntheticEvent, useCallback, useState } from 'react'

export type ImageOrPlaceholderOnError = ImageProps & {
  fallbackElement: ReactElement
}

const ImageOrPlaceholderOnError = ({
  onError,
  alt,
  fallbackElement,
  ...props
}: ImageOrPlaceholderOnError) => {
  const [hasError, setHasError] = useState(false)

  const handleError = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      setHasError(true)

      onError?.(e)
    },
    [onError]
  )

  if (hasError) return fallbackElement

  return <Image {...props} onError={handleError} alt={alt} />
}

export default ImageOrPlaceholderOnError
