'use client'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

import styles from './RichText.module.css'

const RichTextStrikeButton = () => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleStrike().run()}
      disabled={!editor.can().chain().focus().toggleStrike().run()}
      data-is-active={editor.isActive('strike')}
      className={styles.strike}
    >
      S
    </button>
  )
}

export default RichTextStrikeButton
