import classNames from 'classnames'
import { CSSProperties } from 'react'

import { BoxProps } from '@/components/atoms/Box/Box'

import styles from './LoadingSpinner.module.css'

type LoadingSpinnerProps = BoxProps & {
  size?: string
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
  size = '1em',
  ...props
}) => {
  const spinnerStyle = {
    '--spinner-size': size,
  }

  return (
    <div
      className={classNames(className, styles.loadingSpinner)}
      role="status"
      aria-label="Loading"
      {...props}
      style={spinnerStyle as CSSProperties}
    >
      <div className={styles.spinner} aria-hidden="true"></div>
    </div>
  )
}

export default LoadingSpinner
