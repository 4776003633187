'use client'
import { ReactNode, createContext, useRef, useState } from 'react'

import { PopUpAlertStatus } from './PopUpAlert'

type DisplayActionProps = {
  title: string
  description?: string | undefined
}

export type PopUpAlertContextResult = {
  title: string
  isVisible: boolean
  description?: string
  status: PopUpAlertStatus
  displaySuccessAlert: (props: DisplayActionProps) => void
  displayWarningAlert: (props: DisplayActionProps) => void
  displayErrorAlert: (props: DisplayActionProps) => void
  closeAlert: () => void
}

// This could be a createServerContext() but that was causing errors in the current version of React
export const PopUpAlertContext = createContext<PopUpAlertContextResult>(
  {} as PopUpAlertContextResult
)

const TIME_TO_DISMISS_IN_MS = 3500

export const PopUpAlertProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [status, setStatus] = useState<PopUpAlertStatus>('error')

  const timeout = useRef<any>(null)

  const closeAlert = () => {
    setIsVisible(false)
    clearTimeout(timeout.current)
  }

  const displayAndDismissAlert = () => {
    if (isVisible) {
      return
    }

    setIsVisible(true)

    timeout.current = setTimeout(() => {
      closeAlert()
    }, TIME_TO_DISMISS_IN_MS)
  }

  const setAlertTitleAndDescription = (title: string, description?: string) => {
    setTitle(title)

    if (description) {
      setDescription(description)
    }
  }

  const displaySuccessAlert = ({ title, description }: DisplayActionProps) => {
    setStatus('success')
    setAlertTitleAndDescription(title, description)

    displayAndDismissAlert()
  }

  const displayWarningAlert = ({ title, description }: DisplayActionProps) => {
    setStatus('warning')
    setAlertTitleAndDescription(title, description)

    displayAndDismissAlert()
  }

  const displayErrorAlert = ({ title, description }: DisplayActionProps) => {
    setStatus('error')
    setAlertTitleAndDescription(title, description)

    displayAndDismissAlert()
  }

  return (
    <PopUpAlertContext.Provider
      value={{
        title,
        description,
        isVisible,
        status,
        displaySuccessAlert,
        displayWarningAlert,
        displayErrorAlert,
        closeAlert,
      }}
    >
      {children}
    </PopUpAlertContext.Provider>
  )
}
