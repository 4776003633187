import classNames from 'classnames'

import { IconProps } from '@/components/icons/Icon'
import IconContainer from '@/components/icons/IconContainer'

import styles from './CopyIcon.module.css'

const CopyIcon = ({
  color = 'currentColor',
  className,
  hasOverlay,
  ...props
}: IconProps) => {
  return (
    <IconContainer
      className={classNames(styles.iconContainer, className)}
      hasOverlay={hasOverlay}
    >
      <svg
        className={styles.icon}
        height="20"
        width="20"
        fill="none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <mask id="path-1-inside-1_866_29054" fill="white">
          <rect height="10.8333" width="10.8333" rx="1" x="7.5" y="7.5" />
        </mask>
        <rect
          className={styles.backLayer}
          height="10.8333"
          width="10.8333"
          rx="1"
          stroke={color}
          strokeWidth="4"
          x="7.5"
          y="7.5"
          mask="url(#path-1-inside-1_866_29054)"
        />
        <path
          className={styles.frontLayer}
          d="M12.5 5V3.5C12.5 2.94772 12.0523 2.5 11.5 2.5H3.5C2.94772 2.5 2.5 2.94771 2.5 3.5V12.3333C2.5 12.8856 2.94772 13.3333 3.5 13.3333H5"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </svg>
    </IconContainer>
  )
}

export default CopyIcon
