'use client'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

const RichTextItalicButton = () => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleItalic().run()}
      disabled={!editor.can().chain().focus().toggleItalic().run()}
      data-is-active={editor.isActive('italic')}
      aria-label="Italic"
    >
      /
    </button>
  )
}

export default RichTextItalicButton
