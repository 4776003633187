export const VIDEO_FORMATS = ['mp4', 'webm', 'mov']
export const IMAGE_FORMATS = ['png', 'jpg', 'jpeg', 'ogg', 'webp']

export const MEDIA_FORMATS = [...IMAGE_FORMATS, ...VIDEO_FORMATS]
export const DOC_FORMATS = ['doc', 'docx', 'csv', 'pdf', 'xlms', 'xls', 'xlsx']

export const ACCEPTED_FILE_FORMATS = [
  ...MEDIA_FORMATS.map((format) => `.${format}`),
  ...DOC_FORMATS.map((format) => `.${format}`),
].join(', ')
