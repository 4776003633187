'use client'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

import styles from './RichText.module.css'

const RichTextUnderlineButton = () => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleUnderline().run()}
      disabled={!editor.can().chain().focus().toggleUnderline().run()}
      data-is-active={editor.isActive('underline')}
      className={styles.underline}
    >
      U
    </button>
  )
}

export default RichTextUnderlineButton
