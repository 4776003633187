import classNames from 'classnames'
import { ReactNode } from 'react'

import styles from './RichText.module.css'

type RichTextHeaderProps = {
  children?: ReactNode
  className?: string
}

const RichTextHeader = ({ children, className }: RichTextHeaderProps) => {
  return <div className={classNames(styles.menuBar, className)}>{children}</div>
}

export default RichTextHeader
