'use client'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

import styles from './RichText.module.css'

const RichTextHighlightButton = () => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleHighlight().run()}
      disabled={!editor.can().chain().focus().toggleHighlight().run()}
      data-is-active={editor.isActive('highlight')}
      className={styles.highlight}
    >
      @
    </button>
  )
}

export default RichTextHighlightButton
