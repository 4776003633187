'use client'
import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

const RichTextBulletListButton = () => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <button
      type="button"
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      data-is-active={editor.isActive('bulletList')}
      aria-label="Bullet List"
    >
      <svg
        height="16"
        width="16"
        fill="none"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 3H15V4.25H3V3ZM3 7.375H15V8.625H3V7.375ZM3 11.75H15V13H3V11.75Z"
          fill="#BDC1CF"
        />
        <circle cx="1" cy="4" fill="#BDC1CF" r="1" />
        <circle cx="1" cy="8" fill="#BDC1CF" r="1" />
        <circle cx="1" cy="12" fill="#BDC1CF" r="1" />
      </svg>
    </button>
  )
}

export default RichTextBulletListButton
