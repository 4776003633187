'use client'
import Link, { LinkProps } from 'next/link'
import {
  ForwardedRef,
  MouseEventHandler,
  forwardRef,
  useCallback,
  useTransition,
} from 'react'

import { BoxProps } from '@/components/atoms/Box/Box'
import useFullPrefetch from '@/hooks/useFullPrefetch'
import useViewTransitionRouter from '@/hooks/useViewTransitionRouter'

export type LinkWithEagerPrefetchingProps = Omit<LinkProps, 'as'> &
  BoxProps<'a'> & {
    onClick?: (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      transition: ViewTransition
    ) => void | Promise<void>
  }

const LinkWithEagerPrefetching = (
  { children, href, onClick, ...props }: LinkWithEagerPrefetchingProps,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  const [isPending, startTransition] = useTransition()

  const router = useViewTransitionRouter()

  useFullPrefetch(href)

  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (!document.startViewTransition) {
        return startTransition(() => onClick?.(e))
      }

      e.preventDefault()

      router.push(href)

      onClick?.(e)
    },
    [onClick, startTransition, href, router]
  )

  return (
    <Link
      ref={ref}
      href={href}
      onClick={handleClick}
      aria-busy={isPending}
      {...props}
    >
      {children}
    </Link>
  )
}

export default forwardRef(LinkWithEagerPrefetching)
