'use client'
import { useFormStatus } from 'react-dom'

import LoadingSpinner from '@/components/atoms/LoadingSpinner/LoadingSpinner'

import classNames from 'classnames'
import { BoxProps } from '@/components/atoms/Box/Box'
import SendIcon from '@/components/icons/SendIcon'

import styles from './RichText.module.css'

const RichTextSubmitButton = ({ className, ...props }: BoxProps<'div'>) => {
  const { pending } = useFormStatus()

  return (
    <div
      className={classNames(className, styles.submitButtonContainer)}
      {...props}
    >
      {pending ? (
        <LoadingSpinner size="1.5rem" color="var(--color-primary-light)" />
      ) : (
        <button type="submit" aria-label="Send message">
          <SendIcon
            className={styles.sendIcon}
            color="var(--color-primary-light)"
            size="1.5rem"
          />
        </button>
      )}
    </div>
  )
}

export default RichTextSubmitButton
