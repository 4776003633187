import styles from '@/app/styles.json'

import Icon, { IconProps } from './Icon'

const PlusCircleIcon = ({ color = 'currentColor', ...props }: IconProps) => {
  return (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
      <circle
        cx="8"
        cy="8"
        r="7.5"
        stroke={color ?? styles.color['neutral-0']}
      />
      <path
        d="M7.4625 11.2344V4.01562H8.6875V11.2344H7.4625ZM4.46563 8.2375V7.0125H11.6844V8.2375H4.46563Z"
        fill={color ?? styles.color['neutral-0']}
      />
    </Icon>
  )
}

export default PlusCircleIcon
