'use client'
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types'
import { useRouter } from 'next/navigation'
import { useCallback, useEffect } from 'react'

const useFullPrefetch = (href: string) => {
  const router = useRouter()

  const prefetch = useCallback(() => {
    if (
      typeof href === 'string' &&
      href !== '#'
      //
      // && process.env.NODE_ENV === 'production'
    ) {
      router.prefetch(href, { kind: PrefetchKind.FULL })
    }
  }, [router, href])

  useEffect(() => {
    prefetch()
  }, [prefetch])

  return prefetch
}

export default useFullPrefetch
