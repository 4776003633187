import { useState, useEffect, useCallback } from 'react'
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom'

type BrowserTabFocusProps = {
  onFocus?: () => void
  onBlur?: () => void
}

const useBrowserTabFocus = (props?: BrowserTabFocusProps) => {
  const [isTabFocused, setIsTabFocused] = useState<boolean>(true)

  const onFocus = props?.onFocus
  const onBlur = props?.onBlur

  const handleFocus = useCallback(() => {
    batchedUpdates(() => {
      setIsTabFocused(true)

      onFocus?.()
    })
  }, [onFocus])

  const handleBlur = useCallback(() => {
    batchedUpdates(() => {
      setIsTabFocused(false)

      onBlur?.()
    })
  }, [onBlur])

  useEffect(() => {
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [handleFocus, handleBlur])

  return { isTabFocused }
}

export default useBrowserTabFocus
