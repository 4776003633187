const B_TO_KB_LIMIT = 1024
const B_TO_MB_LIMIT = B_TO_KB_LIMIT * 1024

export const bytesToGreaterUnitString = (bytes: number) => {
  if (bytes < B_TO_KB_LIMIT) {
    return bytes + ' B'
  }

  if (bytes < B_TO_MB_LIMIT) {
    return Math.round(bytes / B_TO_KB_LIMIT) + ' Kb'
  }

  return Math.round(bytes / B_TO_MB_LIMIT) + ' Mb'
}
