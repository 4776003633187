import { BoxProps } from '@/components/atoms/Box/Box'
import classNames from 'classnames'
import { IconProps } from '@/components/icons/Icon'

import styles from './IconContainer.module.css'

export type IconContainerProps = BoxProps<'span'> & {
  hasOverlay?: IconProps['hasOverlay']
}

const IconContainer = ({
  children,
  className,
  hasOverlay,
  ...props
}: IconContainerProps) => {
  return (
    <span
      className={classNames(className, styles.iconContainer)}
      data-has-overlay={hasOverlay}
      {...props}
    >
      {children}
    </span>
  )
}

export default IconContainer
