import { useEffect, useRef } from 'react'

type CallbackFunction = () => void

export function useInterval(
  callback: CallbackFunction,
  delay: number | null
): void {
  const savedCallback = useRef<CallbackFunction>()

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      const intervalId = setInterval(tick, delay)

      // Clean up the interval on unmount
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [delay])
}
