import styles from '@/app/styles.json'

import Icon, { IconProps } from './Icon'

const CloseIcon = ({ color = 'currentColor', ...props }: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M18.75 5.25L5.25 18.75"
        stroke={color ?? styles.color['neutral-0']}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 18.75L5.25 5.25"
        stroke={color ?? styles.color['neutral-0']}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}

export default CloseIcon
