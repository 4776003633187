'use client'
import { EditorContent } from '@tiptap/react'
import classNames from 'classnames'

import { useRichTextContent } from '@/components/atoms/RichText/RichTextProvider'

import styles from './RichText.module.css'

type RichTexContentProps = {
  className?: string
}

const RichTextContent = ({ className, ...props }: RichTexContentProps) => {
  const { editor } = useRichTextContent()

  if (!editor) return null

  return (
    <EditorContent
      {...props}
      editor={editor}
      className={classNames(styles.richTextContent, className)}
    />
  )
}

export default RichTextContent
