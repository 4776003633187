import sanitizeHtmlLib from 'sanitize-html'

export const capitalizeString = (s?: string | null) => {
  if (!s) {
    return
  }

  return s.charAt(0).toUpperCase() + s.substring(1)
}

export const sanitizeHtml = (rawHTML?: string) => {
  if (!rawHTML) {
    return ''
  }

  return sanitizeHtmlLib(rawHTML, {
    allowedTags: [
      'p',
      'b',
      'strong',
      'em',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'li',
      'ul',
      'i',
      'u',
      's',
      'mark',
    ],
  })
}

export const clearHtml = (rawHTML?: string) => {
  if (!rawHTML) {
    return ''
  }

  return sanitizeHtmlLib(rawHTML, {
    allowedTags: [],
    allowedAttributes: {},
  })
}

export const truncateText = (text: string, maxLength: number): string => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
}
