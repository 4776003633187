'use client'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { Transaction } from '@tiptap/pm/state'
import { Editor, EditorOptions, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { ReactNode, createContext, useContext, useEffect } from 'react'

import styles from './RichText.module.css'

export type RichTextProviderProps = Partial<EditorOptions> & {
  defaultValue?: string
  children?: ReactNode
  setExerciseSuggestionsContainer?: (element: HTMLElement | null) => void
}

type RichTextContextResult = {
  editor: Editor | null
}

const RichTextContext = createContext({} as RichTextContextResult)

export const useRichTextContent = () => useContext(RichTextContext)

export type OnUpdateProps = {
  editor: Editor
  transaction: Transaction
}

// We can add Tiptap's Mention extension on future, but for now it doesn't worth the > 2 MB weight of dependencies
const CustomHighlight = Highlight.extend({
  addKeyboardShortcuts() {
    return {
      'Shift-2': () => this.editor.commands.toggleHighlight(),
      // Remove highlight when press space
      Space: () =>
        this.editor.isActive('highlight')
          ? this.editor.commands.toggleHighlight()
          : false,
    }
  },
})

const RichTextProvider = ({
  children,
  defaultValue,
  extensions,
  setExerciseSuggestionsContainer,
  ...props
}: RichTextProviderProps) => {
  const editor = useEditor({
    ...props,
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      // TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Underline,
      CustomHighlight.configure({
        HTMLAttributes: {
          class: styles.highlight,
        },
      }),
      ...(extensions ?? []),
    ],
    content: defaultValue,
  })

  useEffect(() => {
    if (defaultValue && defaultValue !== editor?.getHTML()) {
      // Defer the update to avoid the lifecycle conflicts
      Promise.resolve().then(() => {
        editor?.commands.setContent(defaultValue)
      })
    }
  }, [defaultValue, editor])

  return (
    <RichTextContext.Provider
      value={{
        editor,
      }}
    >
      {children}
    </RichTextContext.Provider>
  )
}

export const RichTextConsumer = RichTextContext.Consumer

export default RichTextProvider
